
import React from "react";
export default function Theme(props) {
  return (<div className="container-fluid no-gutters">
    <div className="row align-items-center" style={{ height: '100vh', backgroundRepeat: 'no-repeat', backgroundImage: 'url("https://cdn.galaxy.tf/thumb/sizeW1920/uploads/2s/cms_image/001/530/094/1530094427_5b33635b760ec-thumb.jpg")', backgroundSize: 'cover' }}>
      <div className="col-12" style={{ textAlign: 'center' }}>
        <div className="container">
          <div className="row">
            <div className="col-auto">
              <div className="login-section">
                <img style={{ width: '200px', paddingBottom: '20px' }} src="https://cdn.galaxy.tf/thumb/sizeW500/uploads/3s/cms_image/001/532/400/1532400262_5b5692869f86a-thumb.png" />
                {props.children}            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
}
